import React from 'react';

const NavBar = () => {
    return (
        <nav className='navbar navbar-default '>
            <div className='container'>
                <div className='navbar-header'>
                    <button type='button' className='navbar-toggle collapsed' data-toggle='collapse' data-target='#navigation'>
                        <span className='sr-only'>Toggle navigation</span>
                        <span className='icon-bar'></span>
                        <span className='icon-bar'></span>
                        <span className='icon-bar'></span>
                    </button>
                    {/* <a className='navbar-brand' href=''><img src='' alt=''/></a> */}
                </div>

                <div className='collapse navbar-collapse yamm' id='navigation'>
                    <div className='button navbar-left' style={{paddingTop: '22px'}}>
                        <a className='navbar-btn nav-button wow fadeInRight' href='/add-property' data-wow-delay='0.5s'>أضف عقارك</a>
                    </div>

                    <div className='navbar-right'>
                        <img src={ localStorage.getItem('logo') } alt='logo' className='wow pulse' data-wow-delay='1s' style={{ height: '92px' }} />
                    </div>

                    <ul className='main-nav nav navbar-nav navbar-right'>
                        <li className='wow fadeInDown' data-wow-delay='0.4s'><a href='/contact-us'>الشكاوي والمقترحات</a></li>
                        {/* <li className='wow fadeInDown' data-wow-delay='0.4s'><a href='/favorites'>المفضلة</a></li> */}
                        <li className='wow fadeInDown' data-wow-delay='0.4s'><a href='/installment-calculation'>حاسبة القروض</a></li>
                        <li className='wow fadeInDown' data-wow-delay='0.1s'><a className='' href='/investment'>طلب الإستثمار</a></li>
                        <li className='wow fadeInDown' data-wow-delay='0.1s'><a className='' href='/request-property'>اطلب عقارك</a></li>
                        <li className='wow fadeInDown' data-wow-delay='0.1s'><a className='' href='/properties'>العقارات</a></li>
                        <li className='wow fadeInDown' data-wow-delay='0.1s'><a className='' href='/home'>الرئيسية</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;