import React from 'react';
const FeaturesArea = () => {
    return (
        <div className='Welcome-area'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 Welcome-entry  col-sm-12'>
                        <div className='col-md-5 col-md-offset-2 col-sm-6 col-xs-12'>
                            <div className='welcome_text wow fadeInLeft' data-wow-delay='0.3s' data-wow-offset='100'>
                            </div>
                        </div>
                        <div className='col-md-5 col-sm-6 col-xs-12'>
                            <div  className='welcome_services wow fadeInRight' data-wow-delay='0.3s' data-wow-offset='100'>
                                <div className='row'>
                                    <div className='col-xs-6 m-padding'>
                                        <div className='welcome-estate'>
                                            <div className='welcome-icon'>
                                                <i className='pe-7s-cash pe-4x'></i>
                                            </div>
                                            <p style={{ marginTop: '10px' }}>نوفر عنك عناء الدفعه الاولى</p>
                                        </div>
                                    </div>
                                    
                                    <div className='col-xs-6 m-padding'>
                                        <div className='welcome-estate'>
                                            <div className='welcome-icon'>
                                                <i className='pe-7s-home pe-4x'></i>
                                            </div>
                                            <p style={{ marginTop: '10px' }}>إمتلكها بأقساط شهرية ميسرة</p>
                                        </div>
                                    </div>

                                    <div className='col-xs-12 text-center'>
                                        <i className='welcome-circle'></i>
                                    </div>

                                    <div className='col-xs-6 m-padding'>
                                        <div className='welcome-estate'>
                                            <div className='welcome-icon'>
                                                <i className='pe-7s-search pe-4x'></i>
                                            </div>
                                            <p style={{ marginTop: '10px' }}>ابحث بالقسط المناسب معك </p>
                                        </div>
                                    </div>

                                    <div className='col-xs-6 m-padding'>
                                        <div className='welcome-estate'>
                                            <div className='welcome-icon'>
                                                <i className='pe-7s-users pe-4x'></i>
                                            </div>
                                            <p style={{ marginTop: '10px' }}>خدمة عملاء علي مدار الساعة</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeaturesArea;