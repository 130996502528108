import React, { useState } from 'react';
import axios from 'axios';
import API from './../../API';

const InstallmentCalculation = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [period, setPeriod] = useState(0);
    const [calculation, setCalculation] = useState({});
    const [propertyCost, setPropertyCost] = useState(0);
    const [financialSupport, setFinancialSupport] = useState(0);
    const [display, setDisplay] = useState(false);
    const submit = () => {
        if (!period || !financialSupport || !propertyCost) {
            return;
        }
        axios.post(`${API}/properties/installment-calculation`, {
            name,
            email,
            phone,
            period: +period,
            propertyCost: +propertyCost,
            financialSupport: +financialSupport,
        }).then(res => {
            const { data } = res;
            setCalculation(data);
            setDisplay(true);
        }).catch( err => {
            setCalculation({});
            setDisplay(false);
        });
    };
    return (
        <div>
            <div className='page-head'> 
                <div className='container'>
                    <div className='row'>
                        <div className='page-head-content'>
                            <h1 className='page-title'> حاسبة القروض </h1>               
                        </div>
                    </div>
                </div>
            </div>

            <div className='content-area submit-property' style={{backgroundColor :'#FCFCFC'}}>
                <div className='container'>
                    <div className='clearfix' > 
                        <div className='wizard-container'> 
                            <div className='wizard-card ct-wizard-orange' id='wizardInstallment'>
                                <form action='' method=''>                        
                                    <div className='wizard-header'>
                                        <h3>
                                            <b>حاسبة</b> القروض <br/>
                                            <small> إحسب قيمة القسط المناسب لك </small>
                                        </h3>
                                    </div>

                                    <ul>
                                        <li style={{float: 'right'}}><a href='#step1' data-toggle='tab'>بيانات العميل</a></li>
                                        <li style={{float: 'right'}}><a href='#step2' data-toggle='tab'>حاسبة القروض</a></li>
                                    </ul>

                                    <div className='tab-content'>

                                        <div className='tab-pane' id='step1'>
                                            <div className='row p-b-15'>
                                                <h4 className='info-text'></h4>
                                                <div className='col-sm-12'>
                                                    <div className='form-group'>
                                                        <label>الإسم<small>(مطلوب)</small></label>
                                                        <input name='name' value={name} type='text' className='form-control' required onChange={(e) => setName(e.target.value)}/>
                                                    </div>

                                                    <div className='form-group'>
                                                        <label>رقم الجوال<small>(مطلوب)</small></label>
                                                        <input name='phone' value={phone} type='text' className='form-control' required onChange={(e) => setPhone(e.target.value)}/>
                                                    </div> 
                                                    <div className='form-group'>
                                                        <label>البريد الإليكتروني</label>
                                                        <input name='email' value={email} type='email' className='form-control' required onChange={(e) => setEmail(e.target.value)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='tab-pane' id='step2'>
                                            <h4 className='info-text'> حاسبة القروض </h4>
                                            <div className='row'>
                                                <div className='col-sm-6'> 
                                                    <div className='col-sm-12'> 
                                                        <div className='form-group'>
                                                            <label>قيمة العقار</label>
                                                            <input type='number' name='propertyCost' value={propertyCost} className='form-control' onChange={(e) => { setPropertyCost(e.target.value); submit(); }} required/>
                                                        </div> 
                                                    </div> 
                                                </div>
                                                <div className='col-sm-6'> 
                                                    <div className='col-sm-12'> 
                                                        <div className='form-group'>
                                                            <label htmlFor='property-video'> مدة التمويل (بالشهور)</label>
                                                            <input type='number' name='period' value={period} className='form-control' onChange={(e) => { setPeriod(e.target.value); submit(); }} required/>
                                                        </div>
                                                    </div> 
                                                </div>
                                                
                                                <div className='col-sm-12 padding-top-15'>
                                                    <div className='col-sm-6'>
                                                        <div className='form-group'>
                                                            <label htmlFor='price-range'>قيمة القسط الشهري</label>
                                                            <p> <b> <h4> {calculation?.monthly ? calculation?.monthly - financialSupport : '0.00'} </h4> </b> </p>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div className='form-group'>
                                                            <label htmlFor='price-range'> قيمة الدعم الشهري </label>
                                                            <input type='number' name='financialSupport'  value={financialSupport} className='form-control' onChange={(e) => { setFinancialSupport(e.target.value); submit(); }} required/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                            </div>

                                            { display ? <div className='row'>
                                                <ul className='additional-details-list clearfix'>
                                                    <li>
                                                        <span className='col-xs-6 col-sm-8 col-md-8 add-d-entry'>{Number(propertyCost).toLocaleString()}</span>
                                                        <span className='col-xs-6 col-sm-4 col-md-4 add-d-title'>قيمة العقار</span>
                                                    </li>
                                                    <li>
                                                        <span className='col-xs-6 col-sm-8 col-md-8 add-d-entry'>{period}</span>
                                                        <span className='col-xs-6 col-sm-4 col-md-4 add-d-title'>مدة التمويل</span>
                                                    </li>
                                                    <li>
                                                        <span className='col-xs-6 col-sm-8 col-md-8 add-d-entry'>{Number(calculation?.firstCash).toLocaleString()}</span>
                                                        <span className='col-xs-6 col-sm-4 col-md-4 add-d-title'>الدفعة الأولي ({calculation?.firstCashPercentage}%) </span>
                                                    </li>
                                                    <li>
                                                        <span className='col-xs-6 col-sm-8 col-md-8 add-d-entry'>{Number(calculation?.installmentAmount).toLocaleString()}</span>
                                                        <span className='col-xs-6 col-sm-4 col-md-4 add-d-title'>مبلغ التمويل </span>
                                                    </li>
                                                    <li>
                                                        <span className='col-xs-6 col-sm-8 col-md-8 add-d-entry'>{Number(calculation?.interest).toLocaleString()}</span>
                                                        <span className='col-xs-6 col-sm-4 col-md-4 add-d-title'>مجموع الفوائد ({calculation?.percentage}%)</span>
                                                    </li>
                                                    <li>
                                                        <span className='col-xs-6 col-sm-8 col-md-8 add-d-entry'>
                                                            {Number(calculation?.interest + calculation?.installmentAmount).toLocaleString()}
                                                        </span>
                                                        <span className='col-xs-6 col-sm-4 col-md-4 add-d-title'>إجمالي القيمة</span>
                                                    </li>
                                                    <li>
                                                        <span className='col-xs-6 col-sm-8 col-md-8 add-d-entry'>{Number(calculation?.monthly).toLocaleString()}</span>
                                                        <span className='col-xs-6 col-sm-4 col-md-4 add-d-title'> القسط الشهري </span>
                                                    </li>
                                                    <li>
                                                        <span className='col-xs-6 col-sm-8 col-md-8 add-d-entry'>{Number(financialSupport).toLocaleString()}</span>
                                                        <span className='col-xs-6 col-sm-4 col-md-4 add-d-title'>الدعم</span>
                                                    </li>
                                                    <li>
                                                        <span className='col-xs-6 col-sm-8 col-md-8 add-d-entry'>{Number(calculation?.monthly - financialSupport).toLocaleString()}</span>
                                                        <span className='col-xs-6 col-sm-4 col-md-4 add-d-title'>القسط بعد الدعم</span>
                                                    </li>
                                                </ul>
                                            </div> : <></> }
                                        </div>
                                    </div>

                                    <div className='wizard-footer'>
                                        <div className='pull-left'>
                                            <input type='button' className='btn btn-next btn-primary' name='next' defaultValue='التالي'/>
                                            {/* <input type='button' className='btn btn-finish btn-primary ' name='finish' defaultValue='إحسب' onClick={submit}/> */}
                                        </div>

                                        <div className='pull-right'>
                                            <input type='button' className='btn btn-previous btn-default' name='previous' defaultValue='السابق' />
                                        </div>
                                        <div className='clearfix'></div>                                            
                                    </div>	
                                </form>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InstallmentCalculation;