import React, { useEffect, useState } from 'react';
import axios from 'axios';
import API from './../../API';

const TopProperties = () => {

    const [properties, setProperties] = useState([]);
    const [isLoaded, setLoad] = useState(false);
    useEffect(() => {
        if(!isLoaded) {
            axios.get(`${API}/properties?from=0&to=7`).then(res => {
                setProperties(res?.data);
            }).catch( err => {
                setProperties([]);
            });
            setLoad(true);
        }
    }, []);

    return (
        <div className='content-area home-area-1 recent-property' style={{backgroundColor: '#FCFCFC', paddingBottom: '55px'}}>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-10 col-md-offset-1 col-sm-12 text-center page-title'>
                        <h2>{ properties.length ?' عقارات تم إضافتها مؤخرا ' : ''}</h2>
                        <p></p>
                    </div>
                </div>

                <div className='row'>
                    <div className='proerty-th'>
                        {properties?.map( property => {
                            return (
                                <div style={{ float: 'right' }} className='col-sm-6 col-md-3 p0' key={property?.id}>
                                    <div className='box-two proerty-item'>
                                        <div className='item-thumb'>
                                            <a href={'/property/' + property?.id}>
                                                <img src={property?.images?.filter(el => el.isPrimary).length ? property?.images?.filter(el => el.isPrimary)[0]?.src : property?.images[0]?.src} alt= { property?.name } />
                                            </a>
                                        </div>
                                        <div className='item-entry overflow'>
                                            <h5><a href={'/property/' + property?.id} >{ property?.name?.substring(0,19) }{ property?.name?.length > 19 ? '...' : '' } </a></h5>
                                            <div className='dot-hr'></div>
                                            <span className='pull-left'>{ property?.type?.name} </span>
                                            <span className='proerty-price pull-right'> { property?.cost} ﷼ </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopProperties;