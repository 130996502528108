import React, { useState } from 'react';
import axios from 'axios';
import API from './../../API';

const InvestmentRequest = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [investment, setInvestment] = useState({});
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);
    const submit = () => {
        axios.post(`${API}/properties/investment`, {
            name,
            phone,
            email,
            investmentType: investment?.type,
            propertyName: investment?.property,
            investmentCost: investment?.cost,
        }).then(res => {
            setFailed(false);
            setSuccess(true);
            setName('');
            setEmail('');
            setPhone('');
            setInvestment({});
            setTimeout(() => { window.location.reload(); }, 2000);
        }).catch( err => {
            setFailed(true);
            setSuccess(false);
        });
    };
    return (
        <div>
            <div className='page-head'> 
                <div className='container'>
                    <div className='row'>
                        <div className='page-head-content'>
                            <h1 className='page-title'>طلب الإستثمار</h1>               
                        </div>
                    </div>
                </div>
            </div>

            <div className='content-area submit-property' style={{backgroundColor :'#FCFCFC'}}>
                <div className='container'>
                    <div className='clearfix' > 
                        <div className='wizard-container'> 
                            <div className='wizard-card ct-wizard-orange' id='wizardInvestment'>
                                <form action='' method=''>                        
                                    <div className='wizard-header'>
                                        <h3>
                                            <b>طلب</b> الإستثمار <br/>
                                            <small>إستثمار آمن، بعوائد مجزيه، وبدون مخاطر.</small>
                                        </h3>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            {success ? <div className="alert alert-success" role="alert">تم إرسال طلبك بنجاح</div> : <></> }
                                            {failed ? <div className="alert alert-danger" role="alert">هناك خطأ ما، برجاء المحاولة لاحقًا</div> : <></> }
                                        </div>
                                    </div>

                                    <ul>
                                        <li style={{float: 'right'}}><a href='#step1' data-toggle='tab'>بيانات العميل</a></li>
                                        <li style={{float: 'right'}}><a href='#step2' data-toggle='tab'>طلب الإستثمار</a></li>
                                    </ul>

                                    <div className='tab-content'>

                                        <div className='tab-pane' id='step1'>
                                            <div className='row p-b-15'>
                                                <h4 className='info-text'></h4>
                                                <div className='col-sm-12'>
                                                    <div className='form-group'>
                                                        <label>الإسم<small>(مطلوب)</small></label>
                                                        <input name='name' value={name} type='text' className='form-control' required onChange={(e) => setName(e.target.value)}/>
                                                    </div>

                                                    <div className='form-group'>
                                                        <label>رقم الجوال<small>(مطلوب)</small></label>
                                                        <input name='phone' value={phone} type='text' className='form-control' required onChange={(e) => setPhone(e.target.value)}/>
                                                    </div> 
                                                    <div className='form-group'>
                                                        <label>البريد الإليكتروني</label>
                                                        <input name='email' value={email} type='email' className='form-control' required onChange={(e) => setEmail(e.target.value)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='tab-pane' id='step2'>
                                            <h4 className='info-text'> بيانات الإستثمار </h4>
                                            <div className='row'>
                                                <div className='col-sm-6'> 
                                                    <div className='col-sm-12'> 
                                                        <div className='form-group'>
                                                            <label> العقار المراد الاستثمار فيه: </label>
                                                            <input name='projectName' value={investment?.property} className='form-control' onChange={(e) => setInvestment({ ...investment, property: e.target.value})} required/>
                                                        </div> 
                                                    </div> 
                                                </div>
                                                <div className='col-sm-6'> 
                                                    <div className='col-sm-12'> 
                                                        <div className='form-group'>
                                                            <label>نوع الإستثمار:</label>
                                                            <input name='loanType' value={investment?.type} className='form-control' onChange={(e) => setInvestment({ ...investment, type: e.target.value})} required/>
                                                        </div>
                                                    </div> 
                                                </div>
                                                
                                                <div className='col-sm-12 padding-top-15'>
                                                    <div className='col-sm-12'>
                                                        <div className='form-group'>
                                                            <label>مبلغ الإستثمار:</label>
                                                            <input name='loanAmount'  value={investment?.cost} className='form-control' onChange={(e) => setInvestment({ ...investment, cost: e.target.value})} required/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='wizard-footer'>
                                        <div className='pull-left'>
                                            <input type='button' className='btn btn-next btn-primary' name='next' defaultValue='التالي'/>
                                            <input type='button' className='btn btn-finish btn-primary ' name='finish' defaultValue='قدّم' onClick={submit}/>
                                        </div>

                                        <div className='pull-right'>
                                            <input type='button' className='btn btn-previous btn-default' name='previous' defaultValue='السابق' />
                                        </div>
                                        <div className='clearfix'></div>                                            
                                    </div>	
                                </form>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvestmentRequest;