import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API from './../../API';

const AddProperty = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [property, setProperty] = useState({});
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);
    const [areas, setAreas] = useState([]);
    const [cities, setCities] = useState([]);
    const [terms, setTerms] = useState([]);
    const submit = () => {
        axios.post(`${API}/properties/add`, {
            name,
            phone,
            email,
            projectName: property?.projectName,
            city: property?.city,
            areaId: property?.area,
            space: +property?.space,
            rooms: +property?.rooms,
            baths: +property?.baths,
            details: property?.description,
            images: property?.images,
            publisherType: property?.publisherType ? property?.publisherType : 'مالك',
            installmentValue: property?.installmentValue,
            licenseNumber: property?.licenseNumber,
        }).then(res => {
            setFailed(false);
            setSuccess(true);
            setName('');
            setEmail('');
            setPhone('');
            setProperty({});
            setTimeout(() => { window.location.reload(); }, 2000);
        }).catch( err => {
            setFailed(true);
            setSuccess(false);
        });
           
    };

    const fetchAreas = (cityId) => {
        axios.get(`${API}/cities/${cityId}/areas`).then(res => {
            setAreas(res?.data);
        }).catch( err => {
            setAreas([]);
        });
    }

    useEffect(() => {
        axios.get(`${API}/cities`).then(res => {
            setCities(res?.data);
        }).catch( err => {
            setCities([]);
        });

        axios.get(`${API}/terms-conditions`).then(res => {
            setTerms(res?.data);
        }).catch( err => {
            setTerms([]);
        });
    }, []);
    return (
        <div>
            <div className='page-head'> 
                <div className='container'>
                    <div className='row'>
                        <div className='page-head-content'>
                            <h1 className='page-title'>أضف عقارك</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className='content-area submit-property' style={{backgroundColor :'#FCFCFC'}}>
                <div className='container'>
                    <div className='clearfix' > 
                        <div className='wizard-container'> 
                            <div className='wizard-card ct-wizard-orange' id='wizardProperty'>
                                <form action='' method=''>                        
                                    <div className='wizard-header'>
                                        <h3>
                                            <b>أضف</b> عقارك <br/>
                                            <small>سجل عقارك الان</small>
                                        </h3>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            {success ? <div className='alert alert-success' role='alert'>تم إرسال طلبك بنجاح</div> : <></> }
                                            {failed ? <div className='alert alert-danger' role='alert'>هناك خطأ ما، برجاء المحاولة لاحقًا</div> : <></> }
                                        </div>
                                    </div>

                                    <ul>
                                        <li style={{float: 'right'}}><a href='#step1' data-toggle='tab'>بيانات العميل</a></li>
                                        <li style={{float: 'right'}}><a href='#step2' data-toggle='tab'>بيانات العقار</a></li>
                                        {/* <li style={{float: 'right'}}><a href='#step3' data-toggle='tab'>تفاصيل العقار</a></li> */}
                                        <li style={{float: 'right'}}><a href='#step4' data-toggle='tab'>مراجعة طلبك</a></li>
                                    </ul>

                                    <div className='tab-content'>

                                        <div className='tab-pane' id='step1'>
                                            <div className='row p-b-15'>
                                                <div className='col-sm-12'>
                                                    <div className='form-group'>
                                                        <label>الإسم<small>(مطلوب)</small></label>
                                                        <input name='name' type='text' className='form-control' defaultValue={name} required onChange={(e) => setName(e.target.value)}/>
                                                    </div>
                                                    <div className='form-group'>
                                                        <label>رقم الجوال<small>(مطلوب)</small></label>
                                                        <input name='phone' type='text' className='form-control' defaultValue={phone} required onChange={(e) => setPhone(e.target.value)}/>
                                                    </div>
                                                    <div className='form-group'>
                                                        <label>البريد الإليكتروني</label>
                                                        <input name='email' type='email' className='form-control' defaultValue={email} required onChange={(e) => setEmail(e.target.value)}/>
                                                    </div>
                                                    <div className='form-group'>
                                                        <label htmlFor='publisherType'>طبيعة الناشر</label>
                                                        <select name='publisherType' className='form-control' onChange={(e) => setProperty({ ...property, publisherType: e.target.value})}>
                                                            <option value='مالك' selected={property?.publisherType === 'مالك'}> مالك </option>
                                                            <option value='وسيط' selected={property?.publisherType === 'وسيط'}> وسيط </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='tab-pane' id='step2'>
                                            <h4 className='info-text'> بيانات العقار </h4>
                                            <div className='row'>
                                                <div className='col-sm-12'> 
                                                    <div className='col-sm-6'> 
                                                        <div className='form-group'>
                                                            <label>رقم الترخيص:</label>
                                                            <input name='licenseNumber' className='form-control' onChange={(e) => setProperty({ ...property, licenseNumber: e.target.value})} required/>
                                                        </div> 
                                                    </div>
                                                    <div className='col-sm-6'> 
                                                        <div className='form-group'>
                                                            <label>المشروع:</label>
                                                            <input name='projectName' className='form-control' onChange={(e) => setProperty({ ...property, projectName: e.target.value})} required/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-12'>
                                                    <div className='col-sm-6'>
                                                        <div className='form-group'>
                                                            <label>الحي:</label>
                                                            <select className='form-control' name='area' title='الحي' onChange={(e) => setProperty({ ...property, area: e.target.value})} defaultValue={property?.area} required>
                                                                <option key='empty1'> -- </option>
                                                                {areas?.map((area, index) => {
                                                                    return(<option key={index} value={area?.id} selected={property?.area === area?.id}> {area?.name}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div className='form-group'>
                                                            <label>المدينة:</label>
                                                            <select className='form-control' name='city' title='المدينة' onChange={(e) => { setProperty({ ...property, city: e.target.value}); fetchAreas(e.target.value)} } defaultValue={property?.city} required>
                                                            <option key='empty2'> -- </option>
                                                            {cities?.map((city, index) => {
                                                                    return(<option key={index} value={city?.id} selected={property?.city === city?.id}> {city?.name}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-12'>
                                                    <div className='col-sm-6'> 
                                                        <div className='form-group'>
                                                            <label>قيمة القسط:</label>
                                                            <input type='number' min='0' name='installmentValue' className='form-control' onChange={(e) => setProperty({ ...property, installmentValue: e.target.value})} required/>
                                                        </div> 
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div className='form-group'>
                                                            <label>المساحة:</label>
                                                            <input type='number' min='0' className='form-control' name='space' onChange={(e) => setProperty({ ...property, space: e.target.value})} defaultValue={property?.space} required/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-12 padding-top-15'>
                                                    <div className='col-sm-6'>
                                                        <div className='form-group'>
                                                            <label htmlFor='property-geo'>عدد الغرف:</label>
                                                            <input type='number' min='0' className='form-control' name='rooms' onChange={(e) => setProperty({ ...property, rooms: e.target.value})} defaultValue={property?.rooms} required/>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div className='form-group'>
                                                            <label>عدد دورات المياة:</label>
                                                            <input type='number' min='0' className='form-control' name='baths' onChange={(e) => setProperty({ ...property, baths: e.target.value})} defaultValue={property?.baths} required/>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-12'>
                                                        <div className='form-group'>
                                                            <label htmlFor='property-video'>تفاصيل الوحدة:</label>
                                                            <textarea name='description' className='form-control' onChange={(e) => setProperty({ ...property, description: e.target.value})} defaultValue={property?.description} required></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                            </div>
                                        </div>

                                        {/* <div className='tab-pane' id='step3'>                                        
                                            <h4 className='info-text'>تفاصيل العقار</h4>
                                            <div className='row'>
                                                <div className='col-sm-12'> 
                                                    <div className='form-group'>
                                                        <label htmlFor='property-video'>صور الوحدة:</label>
                                                        <input className='form-control' name='images[]' type='file' onChange={(e) => setProperty({ ...property, images: e.target.value})} required multiple/>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className='tab-pane' id='step4'>                                        
                                            <h4 className='info-text'>مراجعة الطلب</h4>
                                            <div className='row'>  
                                                <div className='col-sm-12'>
                                                    <div>
                                                        <ul className='additional-details-list clearfix'>
                                                            <li>
                                                                <span className='col-xs-6 col-sm-8 col-md-8 add-d-entry'>{property?.projectName}</span>
                                                                <span className='col-xs-6 col-sm-4 col-md-4 add-d-title'>المشروع</span>
                                                            </li>
                                                            <li>
                                                                <span className='col-xs-6 col-sm-8 col-md-8 add-d-entry'>{property?.licenseNumber}</span>
                                                                <span className='col-xs-6 col-sm-4 col-md-4 add-d-title'>رقم الترخيص</span>
                                                            </li>
                                                            {/* <li>
                                                                <span className='col-xs-6 col-sm-8 col-md-8 add-d-entry'>{cities.filter(city => city.id === property?.city).name}</span>
                                                                <span className='col-xs-6 col-sm-4 col-md-4 add-d-title'>المدينة</span>
                                                            </li>
                                                            <li>
                                                                <span className='col-xs-6 col-sm-8 col-md-8 add-d-entry'>{areas.filter(area => area.id === property?.area).name}</span>
                                                                <span className='col-xs-6 col-sm-4 col-md-4 add-d-title'>الحي</span>
                                                            </li> */}
                                                            <li>
                                                                <span className='col-xs-6 col-sm-8 col-md-8 add-d-entry'>{property?.space}</span>
                                                                <span className='col-xs-6 col-sm-4 col-md-4 add-d-title'>مساحة العقار</span>
                                                            </li>
                                                            <li>
                                                                <span className='col-xs-6 col-sm-8 col-md-8 add-d-entry'>{property?.installmentValue}</span>
                                                                <span className='col-xs-6 col-sm-4 col-md-4 add-d-title'>قيمة القسط</span>
                                                            </li>
                                                            <li>
                                                                <span className='col-xs-6 col-sm-8 col-md-8 add-d-entry'>{property?.rooms}</span>
                                                                <span className='col-xs-6 col-sm-4 col-md-4 add-d-title'>عدد الغرف</span>
                                                            </li>
                                                            <li>
                                                                <span className='col-xs-6 col-sm-8 col-md-8 add-d-entry'>{property?.baths}</span>
                                                                <span className='col-xs-6 col-sm-4 col-md-4 add-d-title'>عدد دورات المياة</span>
                                                            </li>
                                                            <li>
                                                                <span className='col-xs-6 col-sm-8 col-md-8 add-d-entry'>{property?.description}</span>
                                                                <span className='col-xs-6 col-sm-4 col-md-4 add-d-title'>التفاصيل</span>
                                                            </li>
                                                        </ul>
                                                        <div className='checkbox'>
                                                            <input type='checkbox' id='approve' style= {{width: '1%', marginTop: '8px'}} /> 
                                                            <label style={{marginRight: '2%' }}>
                                                                <a href='#termsModal' data-toggle='modal' data-target='#termsModal'><strong>الموافقة علي الشروط والأحكام.</strong></a>
                                                                <div className='modal fade' id='termsModal' tabIndex='-1' role='dialog' aria-labelledby='termsModalTitle' aria-hidden='true'>
                                                                    <div className='modal-dialog modal-dialog-centered' role='document'>
                                                                        <div className='modal-content'>
                                                                        <div className='modal-header'>
                                                                            <h5 className='modal-title' id='termsModalLongTitle'>الشروط والأحكام</h5>
                                                                            <button type='button' className='close' data-dismiss='modal' aria-label='Close' style={{marginTop: '-25px', float: 'left'}}>
                                                                                <span aria-hidden='true'>&times;</span>
                                                                            </button>
                                                                        </div>
                                                                        <div className='modal-body'>
                                                                            <ol>
                                                                                {terms?.map((term, index) => {
                                                                                    return(<li key={index}> {term?.content} </li>)
                                                                                })}
                                                                            </ol>
                                                                        </div>
                                                                        <div className='modal-footer' style={{ textAlign: 'left'}}>
                                                                            <button type='button' className='btn btn-secondary' data-dismiss='modal'>إغلاق</button>
                                                                            <button type='button' className='btn btn-primary' data-dismiss='modal'>أوافق</button>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=''>
                                        <div className='pull-left'>
                                            <input type='button' className='btn btn-next btn-primary' name='next' defaultValue='التالي'/>
                                            <input type='button' className='btn btn-finish btn-primary ' name='finish' defaultValue='قدًّم' onClick={submit}/>
                                        </div>

                                        <div className='pull-right'>
                                            <input type='button' className='btn btn-previous btn-default' name='previous' defaultValue='السابق' />
                                        </div>
                                        <div className='clearfix'></div>                                            
                                    </div>	
                                </form>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddProperty;