import React from 'react';

const Copyright = () => {
    return (
        <div className='footer-copy text-center'>
            <div className='container'>
                <div className='row'>
                    <div className='pull-left'>
                        <span> (C)ALRehab INTL, All rights reserved 2023  </span> 
                    </div> 
                    <div className='bottom-menu pull-right'> 
                        <ul> 
                            <li><a className='wow fadeInUp animated' data-wow-delay='0.6s' href='/contact-us'>الشكاوي والمقترحات</a></li>
                            <li><a className='wow fadeInUp animated' data-wow-delay='0.4s' href='/request-property'>اطلب عقارك</a></li>
                            <li><a className='wow fadeInUp animated' data-wow-delay='0.6s' href='/investment'>طلب الإستثمار</a></li>
                            <li><a className='wow fadeInUp animated' data-wow-delay='0.4s' href='/add-property'>اضف عقارك</a></li>
                            <li><a className='wow fadeInUp animated' data-wow-delay='0.3s' href='/properties'>العقارات</a></li>
                            <li><a className='wow fadeInUp animated' data-wow-delay='0.2s' href='/home'>الرئيسية</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Copyright;