import React from 'react';
import FooterLinks from './FooterLinks';
import Copyright from './Copyright';

const Footer = () => {
    return (
        <div className='footer-area'>
            <FooterLinks />
            {/* <Copyright /> */}
        </div>
    );
};

export default Footer;