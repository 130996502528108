import React from 'react';
const HomeVideo = () => {
    return (
        <>
            { localStorage.getItem('homeVideo') ? 
                <div className='container-fluid'>
                    <div className='col-xs-12'>
                        <div style={{ margin: '15px' }}>
                            <iframe width="100%" height="600"
                                src={ localStorage.getItem('homeVideo') +'?controls=0&autoplay=1' }>
                            </iframe>
                        </div>
                    </div>
                </div> : <></> }
        </>
    );
};

export default HomeVideo;