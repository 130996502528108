import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API from './../../API';

const RequestProperty = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [property, setProperty] = useState({});
    const [cities, setCities] = useState([]);
    const [types, setTypes] = useState([]);
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);
    const submit = async () => {
        axios.post(`${API}/properties/request`, {
            name,
            phone,
            email,
            cityId: property?.city,
            typeId: property?.propertyType,
            projectName: property?.projectName,
            rooms: +property?.rooms,
            baths: +property?.baths,
            details: property?.propertyDetails,
            priceRange: property?.priceRange,
            areas: JSON.stringify({
                area1: property?.area1,
                area2: property?.area2,
                area3: property?.area3,
            }),
        }).then(res => {
            setFailed(false);
            setSuccess(true);
            setName('');
            setEmail('');
            setPhone('');
            setProperty({});
            setTimeout(() => { window.location.reload(); }, 2000);
        }).catch( err => {
            setFailed(true);
            setSuccess(false);
        });
    };

    useEffect(() => {
        axios.get(`${API}/cities`).then(res => {
            setCities(res?.data);
        }).catch( err => {
            setCities([]);
        });

        axios.get(`${API}/property-types`).then(res => {
            setTypes(res?.data);
        }).catch( err => {
            setTypes([]);
        });
    }, []);
    return (
        <div>
            <div className='page-head'> 
                <div className='container'>
                    <div className='row'>
                        <div className='page-head-content'>
                            <h1 className='page-title'>اطلب عقارك</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className='content-area submit-property' style={{backgroundColor :'#FCFCFC'}}>
                <div className='container'>
                    <div className='clearfix'> 
                        <div className='wizard-container'> 
                            <div className='wizard-card ct-wizard-orange' id='Ashraf'>
                                <form action='' method=''>                        
                                    <div className='wizard-header'>
                                        <h3>
                                            <b>أطلب</b> عقارك <br/>
                                            <small></small>
                                        </h3>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            {success ? <div className="alert alert-success" role="alert">تم إرسال طلبك بنجاح</div> : <></> }
                                            {failed ? <div className="alert alert-danger" role="alert">هناك خطأ ما، برجاء المحاولة لاحقًا</div> : <></> }
                                        </div>
                                    </div>

                                    <ul>
                                        <li style={{float: 'right'}}><a href='#step1' data-toggle='tab'>بيانات العميل</a></li>
                                        <li style={{float: 'right'}}><a href='#step2' data-toggle='tab'>بيانات العقار</a></li>
                                        <li style={{float: 'right'}}><a href='#step3' data-toggle='tab'>مراجعة طلبك</a></li>
                                    </ul>

                                    <div className='tab-content'>
                                        <div className='tab-pane' id='step1'>
                                            <div className='row p-b-15'>
                                                <h4 className='info-text'></h4>
                                                <div className='col-sm-12'>
                                                    <div className='form-group'>
                                                        <label>الإسم<small>(مطلوب)</small></label>
                                                        <input name='name' type='text' className='form-control' required onChange={(e) => setName(e.target.value)}/>
                                                    </div>

                                                    <div className='form-group'>
                                                        <label>رقم الجوال<small>(مطلوب)</small></label>
                                                        <input name='phone' type='text' className='form-control' required onChange={(e) => setPhone(e.target.value)}/>
                                                    </div> 
                                                    <div className='form-group'>
                                                        <label>البريد الإليكتروني</label>
                                                        <input name='email' type='email' className='form-control' required onChange={(e) => setEmail(e.target.value)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='tab-pane' id='step2'>
                                            <h4 className='info-text'></h4>
                                            <div className='row'>
                                                <div className='col-sm-12'> 
                                                    <div className='col-sm-12'> 
                                                        <div className='form-group'>
                                                            <label>المشروع:</label>
                                                            <input name='projectName' className='form-control' onChange={(e) => setProperty({ ...property, projectName: e.target.value})} required/>
                                                        </div> 
                                                    </div> 
                                                </div>
                                                <div className='col-sm-12'> 
                                                    <div className='col-sm-12'> 
                                                        <div className='form-group'>
                                                            <label>تفاصيل الوحدة:</label>
                                                            <textarea name='propertyDetails' className='form-control' onChange={(e) => setProperty({ ...property, propertyDetails: e.target.value})} required></textarea>
                                                        </div>
                                                    </div> 
                                                </div>
                                                <div className='col-sm-12'>
                                                    <div className='col-sm-6'>
                                                        <div className='form-group'>
                                                            <label>المدينة:</label>
                                                            <select className='form-control' name='city' onChange={(e) => setProperty({ ...property, city: e.target.value})} required>
                                                                <option key='empty3'> -- </option>
                                                                {cities?.map((city, index) => {
                                                                    return(<option key={index} value={city?.id}> {city?.name}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className='col-sm-6'>
                                                        <div className='form-group'>
                                                            <label>نوع العقار:</label>
                                                            <select className='form-control' name='type' title='نوع العقار' onChange={(e) => setProperty({ ...property, propertyType: e.target.value})} required>
                                                                <option key='empty3'> -- </option>
                                                                {types?.map((type, index) => {
                                                                    return(<option key={index} value={type?.id}> {type?.name}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-12'>
                                                    <div className='col-sm-4'>
                                                        <div className='form-group'>
                                                            <label>الحي الثالث:</label>
                                                            <input type='text' className='form-control' name='area3' onChange={(e) => setProperty({ ...property, area3: e.target.value})} required/>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-4'>
                                                        <div className='form-group'>
                                                            <label>الحي الثاني:</label>
                                                            <input type='text' className='form-control' name='area2' onChange={(e) => setProperty({ ...property, area2: e.target.value})} required/>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-4'>
                                                        <div className='form-group'>
                                                            <label>الحي الأول:</label>
                                                            <input type='text' className='form-control' name='area1' onChange={(e) => setProperty({ ...property, area1: e.target.value})} required/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-12 padding-top-15'>
                                                    <div className='col-sm-6'>
                                                        <div className='form-group'>
                                                            <label htmlFor='property-geo'>عدد الغرف:</label>
                                                            <input type='number' className='form-control' name='rooms' onChange={(e) => setProperty({ ...property, rooms: e.target.value})} required/>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div className='form-group'>
                                                            <label>عدد دورات المياة:</label>
                                                            <input type='number' className='form-control' name='baths' onChange={(e) => setProperty({ ...property, baths: e.target.value})} required/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-12 padding-top-15'>
                                                    <div className='col-sm-12'>
                                                        <div className='form-group'>
                                                            <label>متوسط السعر:</label>
                                                            <input type='text' className='form-control' name='priceRange' onChange={(e) => setProperty({ ...property, priceRange: e.target.value})} required/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                        <div className='tab-pane' id='step3'>                                        
                                            <h4 className='info-text'>راجع طلبك</h4>
                                            <div className='row'>  
                                                <div className='col-sm-12'>
                                                    <div>
                                                        <ul className="additional-details-list clearfix">
                                                            <li>
                                                                <span className="col-xs-6 col-sm-8 col-md-8 add-d-entry">{property?.projectName}</span>
                                                                <span className="col-xs-6 col-sm-4 col-md-4 add-d-title">المشروع</span>
                                                            </li>
                                                            {/* <li>
                                                                <span className="col-xs-6 col-sm-8 col-md-8 add-d-entry">{property?.city}</span>
                                                                <span className="col-xs-6 col-sm-4 col-md-4 add-d-title">المدينة</span>
                                                            </li> */}
                                                            {/* <li>
                                                                <span className="col-xs-6 col-sm-8 col-md-8 add-d-entry">{property?.propertyType}</span>
                                                                <span className="col-xs-6 col-sm-4 col-md-4 add-d-title">نوع العقار</span>
                                                            </li> */}
                                                            <li>
                                                                <span className="col-xs-6 col-sm-8 col-md-8 add-d-entry">{property?.area1} - {property?.area2} - {property?.area3}</span>
                                                                <span className="col-xs-6 col-sm-4 col-md-4 add-d-title">الأحياء</span>
                                                            </li>
                                                            <li>
                                                                <span className="col-xs-6 col-sm-8 col-md-8 add-d-entry">{property?.rooms}</span>
                                                                <span className="col-xs-6 col-sm-4 col-md-4 add-d-title">عدد الغرف</span>
                                                            </li>
                                                            <li>
                                                                <span className="col-xs-6 col-sm-8 col-md-8 add-d-entry">{property?.baths}</span>
                                                                <span className="col-xs-6 col-sm-4 col-md-4 add-d-title">عدد دورات المياة</span>
                                                            </li>
                                                            <li>
                                                                <span className="col-xs-6 col-sm-8 col-md-8 add-d-entry">{property?.priceRange}</span>
                                                                <span className="col-xs-6 col-sm-4 col-md-4 add-d-title">متوسط السعر</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='wizard-footer'>
                                        <div className='pull-left'>
                                            <input type='button' className='btn btn-next btn-primary' name='next' defaultValue='التالي'/>
                                            <input type='button' className='btn btn-finish btn-primary ' name='finish' defaultValue='قدم' onClick={submit}/>
                                        </div>

                                        <div className='pull-right'>
                                            <input type='button' className='btn btn-previous btn-default' name='previous' defaultValue='السابق' />
                                        </div>
                                        <div className='clearfix'></div>                                            
                                    </div>	
                                </form>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequestProperty;