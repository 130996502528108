import React, { useState } from 'react';
import axios from 'axios';
import API from './../../API';

const ContactUsRequest = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [title, setTitle] = useState('شكوي');
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);
    const submit = async () => {
        axios.post(`${API}/suggestions`, {
            name,
            phone,
            email,
            message,
            title
        }).then(res => {
            setFailed(false);
            setSuccess(true);
            setName('');
            setEmail('');
            setPhone('');
            setTimeout(() => { window.location.reload(); }, 2000);
        }).catch( err => {
            setFailed(true);
            setSuccess(false);
        });
    };
    return (
        <div className='content-area recent-property padding-top-40' style={{backgroundColor: '#FFF' }}>
            <div className='container'>  
                <div className='row'>
                    <div className='col-md-8 col-md-offset-2'> 
                        <div className='' id='contact1'>
                            <h2>الشكاوي والمقترحات</h2>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    {success ? <div className="alert alert-success" role="alert">تم إرسال طلبك بنجاح</div> : <></> }
                                    {failed ? <div className="alert alert-danger" role="alert">هناك خطأ ما، برجاء المحاولة لاحقًا</div> : <></> }
                                </div>
                            </div>
                            <form>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className='form-group'>
                                            <label htmlFor='name'>الإسم</label>
                                            <input type='text' className='form-control' id='name' value={name} required onChange={(e) => setName(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='form-group'>
                                            <label htmlFor='phone'>رقم الجوال</label>
                                            <input type='text' className='form-control' id='phone' value={phone} required onChange={(e) => setPhone(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='form-group'>
                                            <label htmlFor='email'>البريد الإليكتروني</label>
                                            <input type='text' className='form-control' id='email' required value={email} onChange={(e) => setEmail(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='form-group'>
                                            <label htmlFor='subject'>العنوان</label>
                                            <select id='type' className='form-control' data-live-search='true' value={title} data-live-search-style='begins' title='العنوان' required onChange={(e) => setTitle(e.target.value)}>
                                                <option value='شكوي'>شكوي</option>
                                                <option value='مقترح'>مقترح</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <div className='form-group'>
                                            <label htmlFor='message'>الرسالة</label>
                                            <textarea id='message' name='message' className='form-control' required onChange={(e) => setMessage(e.target.value)}></textarea>
                                        </div>
                                    </div>
                                    <div className='col-sm-12 text-center'>
                                        <button type='button' className='btn btn-primary' onClick={submit}><i className='fa fa-envelope-o'></i>إرسال</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    );
};

export default ContactUsRequest;