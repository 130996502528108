import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import API from './../../API';

const PropertiesList = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [properties, setProperties] = useState([]);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(11);
    const [areas, setAreas] = useState([]);
    const [types, setTypes] = useState([]);
    const [areaId, setAreaId] = useState(searchParams.get('area'));
    const [typeId, setTypeId] = useState(searchParams.get('type'));
    const [propertyType, setPropertyType] = useState(searchParams.get('propertyType'));
    const [investmentValue, setInvestmentValue] = useState(searchParams.get('installmentRange'));
    const prepareQuery = () => {
        let params = `from=${from}&&to=${to}`;
        if(areaId) {
            params += `&&areaId=${areaId}`;
        }
        if(typeId) {
            params += `&&typeId=${typeId}`;
        }
        if(propertyType) {
            params += `&&type=${propertyType}`;
        }
        if(investmentValue) {
            params += `&&installmentRange=${investmentValue}`;
        }
        return params;
    }
    const search = () => {
        const params = prepareQuery();
        axios.get(`${API}/properties?${params}`).then(res => {
            setProperties(res?.data);
        }).catch( err => {
            setProperties([]);
        });

        setPropertyType('');
    }

    useEffect(() => {
        axios.get(`${API}/areas`).then(res => {
            setAreas(res?.data);
        }).catch( err => {
            setAreas([]);
        });
        axios.get(`${API}/property-types`).then(res => {
            setTypes(res?.data);
        }).catch( err => {
            setTypes([]);
        });
        search();
    }, []);
    return (
        <>
            <div className='page-head'> 
                <div className='container'>
                    <div className='row'>
                        <div className='page-head-content'>
                            <h1 className='page-title'>العقارات</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className='properties-area recent-property' style={{ backgroundColor: '#FFF;' }}>
                <div className='container'>
                    { properties.length ?
                    <div className='row'>
                        <div className='col-md-9  pr0 padding-top-40 properties-page'>
                            <div className='col-md-12 clear'> 
                                <div id='list-type' className='proerty-th'>
                                { properties?.map(property => {
                                    return(
                                        <div className='col-sm-6 col-md-4 p0'>
                                            <div className='box-two proerty-item'>
                                                <div className='item-thumb'>
                                                    <a href={'/property/' + property?.id} >
                                                        <img src={property?.images?.filter(el => el.isPrimary).length ? property?.images?.filter(el => el.isPrimary)[0]?.src : property?.images[0]?.src} alt={property?.name} />
                                                    </a>
                                                </div>
                                                <div className='item-entry overflow'>
                                                    <h5><a href={'/property/' + property?.id}>{property?.name}</a></h5>
                                                    <div className='dot-hr'></div>
                                                    <span className='pull-left'>{ property?.type?.name } </span>
                                                    <span className='proerty-price pull-right'> { property?.cost } ﷼ </span>
                                                    <p style= {{ display: 'none' }}>{ property?.description }</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3 p0 padding-top-40'>
                            <div className='blog-asside-right pr0'>
                                <div className='panel panel-default sidebar-menu wow fadeInRight animated' >
                                    <div className='panel-heading'>
                                        <h3 className='panel-title'>البحث السريع</h3>
                                    </div>
                                    <div className='panel-body search-widget'>
                                        <form action='' className=' form-inline'> 
                                            <fieldset>
                                                <div className='row'>
                                                    <div className='col-xs-12'>
                                                        <input type='number' className='form-control' min='0' placeholder='قيمة القسط' onChange={(e) => setInvestmentValue(e.target.value)} defaultValue={investmentValue}/>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <fieldset>
                                                <div className='row'>
                                                    <div className='col-xs-6'>
                                                        <select id='lunchBegins' className='selectpicker show-tick form-control' onChange={(e) => setAreaId(e.target.value)} defaultValue={areaId}>
                                                        { areas.map(area => <option key={area.id} value={area.id}> {area.name} </option>)}
                                                        </select>
                                                    </div>
                                                    <div className='col-xs-6'>
                                                        <select id='basic' className='selectpicker show-tick form-control' onChange={(e) => setTypeId(e.target.value)} defaultValue={typeId}>
                                                            { types.map(type => <option key={type.id} value={type.id}> {type.name} </option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <fieldset>
                                                <div className='row'>
                                                    <div className='col-xs-12'>
                                                        <button type='button' className='btn btn-primary' onClick={() => search()}>بحث</button>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : 
                    <div className='content-area error-page' style={{ backgroundColor: '#FCFCFC', paddingBottom: '55px' }}>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-10 col-md-offset-1 col-sm-12 text-center page-title'>
                                    {/* <h2 className='error-title'>خطأ</h2> */}
                                    <p>عفوًا، لا توجد نتائج في الوقت الراهن</p>
                                    <a href='/add-property' className='btn btn-default'>أضف عقارك</a>                        
                                </div>
                            </div> 
                        </div>
                    </div>}
                </div>
            </div>
        </>
    );
};

export default PropertiesList;