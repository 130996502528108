import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import API from '../../API';

const MainSlider = () => {
    const [banners, setBanners] = useState([]);
    const navigate = useNavigate();
    
    const search = (type) => {
        navigate(`/properties?propertyType=${type}`);
    }
    useEffect(() => {
        axios.get(`${API}/home`).then(res => {
            const data = res.data;
            const settings = data.settings[0];
            setBanners(data.banners);
            localStorage.setItem('companyDetails', settings.companyDetails );
            localStorage.setItem('phone', settings.phone );
            localStorage.setItem('email', settings.email );
            localStorage.setItem('address', settings.address );
            localStorage.setItem('homeVideo', settings.homeVideo );
            localStorage.setItem('socialUrls', settings.socialUrls );
            localStorage.setItem('logo', settings.logo );
        }).catch( err => {
            console.log('');
        });
    }, []);
    return (
        <div className='slider-area'>
            <div id="homeCarousel" className="carousel slide" data-ride="carousel" style={{width: '100%'}}>
                <div className="carousel-inner" role="listbox">
                    { banners.map((banner, index) => 
                        <div key={index} className={index === 0 ? 'item active' : 'item'} >
                            <img src={banner.url} alt={banner.alt} style={ {width: '100%', height: '620px'} } />
                        </div>
                    )}

                </div>
            </div>
            <div className='slider-content'>
                <div className='row'>
                    <div className='col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1 col-sm-12'>
                        <button className='btn category-btn' value='شقة' onClick={(e) => search(e.target.value)}>شقق</button>
                        <button className='btn category-btn' value='فيلا' onClick={(e) => search(e.target.value)}>فلل</button>
                        <button className='btn category-btn' value='عمارة' onClick={(e) => search(e.target.value)}>عماير</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainSlider;