import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/home/Home';
import AddProperty from './components/property/AddProperty';
import RequestProperty from './components/property/RequestProperty';
import InvestmentRequest from './components/investement/InvestmentRequest';
import ContactUsRequest from './components/contactUs/ContactUsRequest';
import PropertiesList from './components/property/PropertiesList';
// import FavoritePropertiesList from './components/property/FavoritePropertiesList';
import InstallmentCalculation from './components/investement/InstallmentCalculation';
import PropertyDetails from './components/property/PropertyDetails';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/home' element={<Home />} />
          <Route exact path='/properties' element={<PropertiesList />} />
          <Route exact path='/property/:propertyId' element={<PropertyDetails />} />
          {/* <Route exact path='/favorites' element={<FavoritePropertiesList />} /> */}
          <Route exact path='/installment-calculation' element={<InstallmentCalculation />} />
          <Route exact path='/add-property' element={<AddProperty />} />
          <Route exact path='/request-property' element={<RequestProperty />} />
          <Route exact path='/investment' element={<InvestmentRequest />} />
          <Route exact path='/contact-us' element={<ContactUsRequest />} />
          {/* <Route path='*' element={<Home />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;