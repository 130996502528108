import React from 'react';
import MainSlider from './MainSlider';
import TopProperties from './TopProperties';
import HomeVideo from './HomeVideo';
import FeaturesArea from './FeaturesArea';

const Home = () => {
    const [socialUrls,setSocialUrls] = React.useState('{}');
    React.useEffect(() => {
        const urls = localStorage.getItem('socialUrls');
        setSocialUrls(JSON.parse(urls || '{}'));
    }, []);
    return (
        <>
            <MainSlider />
            <TopProperties />
            <HomeVideo />
            <FeaturesArea />
            <div>
                <a className='btn button-xs'
                    href={'https://wa.me/' + socialUrls?.whatsapp}
                    target="_blank"
                    style={{
                        position: 'fixed',
                        bottom: '10%',
                        left: '3%',
                        fontSize: '35px',
                        color:' #fff',
                        zIndex: '1600',
                        borderRadius: '12px',
                        backgroundColor: '#25d366',
                        paddingBottom: '1px',
                }}> <i className="fa fa-whatsapp my-float"></i>
                </a>
            </div>
        </>
    );
};

export default Home;